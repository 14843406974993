import Splide from '@splidejs/splide';
import { Component } from 'libs/components';

class ImageGalleryComponent extends Component {
    splide;

    onInit(): void {
        // TODO: Implement autoplay.

        this.splide = new Splide(this.host, {
            type: 'loop',
            perPage: 1,
            perMove: 1,
            gap: 25,
            start: 0,
            pagination: false,
            arrows: true,
            autoplay: true,
            interval: 3000,
            speed: 1000,
        });
        this.splide.mount();

    }
}
ImageGalleryComponent.declare('.image-gallery');
