import { Component } from 'libs/components';

class TnbOpener extends Component {
    onInit(): void {
        this.host.addEventListener('click', event => {
            event.preventDefault();
            this.loadHtml((event.target as HTMLElement).getAttribute('href'));
        });
    }

    loadHtml(url) {
        const overlayEl = document.createElement('div');
        overlayEl.classList.add('overlay');

        fetch(url, {
            method: "GET",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            },
        }).then(
            response => {
                return response.text();
            }
        ).then(html => {
            overlayEl.innerHTML = html;
        })

        document.body.appendChild(overlayEl);
        this.attachListener();
    }

    attachListener() {
        const overlayEl = document.querySelector('.overlay');

        overlayEl.addEventListener('click', event => {
            if (event.target === overlayEl) {
                overlayEl.remove();
            }
        });
    }
}
TnbOpener.declare('.contest__tnb');


class TnbCloser extends Component {
    onInit(): void {
        this.host.addEventListener('click', event => {
            event.preventDefault();
            const overlayEl = document.querySelector('.overlay');
            if (overlayEl) {
                overlayEl.remove();
            }
        });
    }
}
TnbCloser.declare('.tnb__closer');