window.addEventListener("DOMContentLoaded", () => {
    const loaderEl = document.getElementById('loader');
    if (loaderEl) {
        runPreloader(loaderEl);
    } else {
        document.body.classList.remove('loader-visible');
    }
});

function runPreloader(loaderEl) {
    const timing = {
        topline: 250,
        headline: 500,
        secondImage: 2000,
        slogan: 2000,
        removeLoader: 5000,
        removeAnimation: 5500
    };

    setTimeout(() => {
        loaderEl.querySelector('.loader__topline .text-mask').classList.add('text-mask--visible');
    }, timing.topline);

    setTimeout(() => {
        loaderEl.querySelector('.loader__headline .text-mask').classList.add('text-mask--visible');
    }, timing.headline);

    setTimeout(() => {
        loaderEl.querySelector('.loader__top__second').classList.add('loader__top__second--visible');
    }, timing.secondImage);

    setTimeout(() => {
        loaderEl.querySelector('.loader__top__slogan .text-mask').classList.add('text-mask--visible');
    }, timing.slogan);

    setTimeout(() => {
        loaderEl.classList.add('loader--hidden');
    }, timing.removeLoader);

    setTimeout(() => {
        loaderEl.remove();
        document.body.classList.remove('loader-visible');
    }, timing.removeAnimation);
}