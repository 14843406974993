import { Component } from 'libs/components';

class IntroArrowComponent extends Component {
    onInit(): void {
        this.host.addEventListener('click', event => {
            event.preventDefault();
            window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
        });
    }
}
IntroArrowComponent.declare('.intro__arrow');


class ScrollToContestComponent extends Component {
    onInit(): void {
        this.host.addEventListener('click', event => {
            event.preventDefault();
            const contestEl = document.getElementById('contest');
            contestEl.scrollIntoView({ behavior: 'smooth' });
        });
    }
}
ScrollToContestComponent.declare('.js-scrollto-contest');